import styled from "styled-components";

import { IvaInfoStyledProps as Props } from "./IvaInfo.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;
const { primary } = variables.fonts;

const IvaInfoStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  min-width: 20rem;
  border-width: 0.1rem;
  border-color: var(--palette-purple-s48-l39);
  border-style: solid;
  border-radius: 0.8rem;
  width: ${({ isSummary }) => (isSummary ? "100%" : "max-content")};
  padding: 0.4rem;
  margin-bottom: 1.6rem;

  .IvaInfo {
    svg {
      fill: var(--palette-purple-s48-l39);
    }

    &__title {
      font-family: ${primary};
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--palette-purple-s48-l39);
      white-space: nowrap;

      @media (max-width: ${tablet}px) {
        white-space: normal;
      }
    }
  }
`;

export default IvaInfoStyled;
