import styled from "styled-components";

import CONSTANTS from "../../../config/constants";
import { NavbarStyledProps as Props } from "./Navbar.types";
import variables from "styles/util/variables";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, desktop } = BREAKPOINTS;
const { primary } = variables.fonts;

const NavbarStyled = styled.div<Props>`
  font-family: ${primary};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: var(--palette-orange-s89-l54);
  height: var(--sizes-navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (width <= ${tablet}px) {
    display: grid;
    grid-template-columns: var(--sizes-page-columns);
  }

  @media (max-width: ${tablet}px) {
    height: ${props =>
      props.showBottomSearch
        ? "var(--sizes-navbar-height-mobile)"
        : "var(--sizes-navbar-height-mobile-without-search)"};
    padding-top: calc(env(safe-area-inset-top) + 1rem);
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .SearchBar__wrapper {
    max-width: 28rem;

    @media (max-width: ${tablet}px) {
      max-width: unset;
    }
  }

  .Navbar {
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 92%;

      @media (max-width: ${tablet}px) {
        width: 100%;
        margin: 0;
      }
    }

    &__sideContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }

    &__top {
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      @media (width <= ${tablet}px) {
        justify-content: space-between;
        gap: 0;
        padding: 0 1.2rem;
      }

      &--brand {
        &.not-home {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }
      }

      &--responsive {
        justify-content: space-between;
        display: none;

        &.not-home {
          @media (max-width: ${tablet}px) {
            display: flex;
          }
        }
      }
    }

    &__logo {
      svg {
        @media (max-width: ${desktop}px) {
          width: 16rem;
        }
      }
    }

    &__categories {
      display: none;

      @media (min-width: ${desktop}px) {
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        display: block;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
    }

    &__dropdown {
      margin-left: 2.4rem;
      flex-shrink: 0;
      border-radius: 2.4rem;

      &-desktop {
        @media (max-width: ${tablet}px) {
          display: none;
        }
      }

      &-mobile {
        @media (min-width: ${tablet}px) {
          display: none;
        }
      }

      @media (max-width: ${desktop}px) {
        margin: 0 0.8rem;
      }
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
    }

    &__back {
      visibility: ${props => (props.talkShop ? "hidden" : "visible")};
    }

    &__bottom {
      display: none;
      flex-wrap: nowrap;
      align-items: center;

      @media (max-width: ${tablet}px) {
        display: flex;
      }
    }

    &__search-bar {
      &--top {
        flex: 1;
        max-width: 28rem;
      }

      &--center {
        margin: 0;
        max-width: 60rem;
        padding: 0 2rem;
        border-radius: 0.8rem;
        overflow: hidden;

        .SearchBar__input {
          max-width: 19.2rem;
        }

        @media (max-width: 360px) {
          padding: 0;
          max-width: 22rem;

          .SearchBar__input {
            max-width: 15.2rem;
          }
        }
      }

      &--bottom {
        margin: 0;
        padding-top: 0.8rem;
        width: 100%;
      }
    }

    &__tabs {
      display: flex;
      align-items: center;

      @media (max-width: ${desktop}px) {
        flex: 0;
        display: flex;
        justify-content: flex-end;
        gap: 0.4rem;
      }
    }

    &__btn {
      visibility: ${props => (props.talkShop ? "hidden" : "visible")};
      border: 1px solid var(--palette-white);
      height: 4rem;
      width: 4rem;
      padding: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: transform 0.5s ease 0s;

      & svg {
        height: 2rem;
      }

      & path {
        fill: var(--palette-white);
      }

      &:not(:last-child) {
        margin-right: 0.8rem;

        @media (max-width: ${tablet}px) {
          margin: 0;
        }
      }

      &--profile,
      &--coupons {
        cursor: pointer;
        @media (width <= ${tablet}px) {
          display: ${props => (props.isAnonymous ? "none" : "block")};
        }
      }

      &--cart {
      }

      &--hidden {
        visibility: hidden;
      }

      &:hover {
        transform: scale(1.09);
      }
    }

    &__welcome {
      padding: 0 2.4rem;
      min-width: 12rem;

      @media (max-width: ${desktop}px) {
        display: none;
      }
    }

    &__search-bar--top,
    &__btn--loyalty,
    &__btn--settings {
      @media (max-width: ${tablet}px) {
        display: none;
      }
    }
  }
`;

export default NavbarStyled;
