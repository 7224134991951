import { useCatalogues, useGeo, useStores } from "@simple/contexts";
import { useFetchGoogleAddressFromCoords } from "@simple/services";
import ArrowSVG from "images/arrow-dropdown.svg";
import React, { useEffect, useState } from "react";

import Button from "../Button/Button";
import Styles from "./SelectAddressButton.styles";
import { SelectAddressButtonProps as Props } from "./SelectAddressButton.types";
import ShippingAddressModal from "components/checkout/ShippingAddressModal/ShippingAddressModal";
import { getENVs } from "config/artisn.config";
import useI18n from "hooks/useI18n";

const SelectAddressButton: React.FC<Props> = props => {
  const { className } = props;
  const t = useI18n();
  const { selectedStore } = useStores();
  const { storeName } = selectedStore ?? {};
  const { selectedCoordinates } = useGeo();
  const [geoCodeAddress, setGeoCodeAddress] = useState<string | undefined>();
  const { selectedCatalogue } = useCatalogues();
  const { name: selectedCatalogueName } = selectedCatalogue;
  const isDelivery = selectedCatalogueName === "Delivery";
  const { data: predictions } = useFetchGoogleAddressFromCoords(
    `${getENVs?.apiKey}`,
    selectedCoordinates
  );

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!predictions || !predictions.length) return;
    const { formatted_address } = predictions[0];
    setGeoCodeAddress(formatted_address);
  }, [predictions]);

  return (
    <Styles className={`SelectAddressButton ${className}`}>
      <Button type="FILLED" color="primary-light" onClick={handleOpenModal}>
        <p className="SelectAddressButton__text">
          {isDelivery ? t.selectAddress.delivery : t.selectAddress.pickUp}
          <span className="SelectAddressButton__span">
            {isDelivery
              ? geoCodeAddress || t.selectAddress.address
              : storeName || t.selectAddress.store}
          </span>
        </p>
        <ArrowSVG className="SelectAddressButton__icon" />
      </Button>
      <ShippingAddressModal opened={showModal} onClose={handleCloseModal} />
    </Styles>
  );
};

SelectAddressButton.defaultProps = {
  className: ""
};

export default SelectAddressButton;
