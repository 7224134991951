import { useCountries, useShoppingCart } from "@simple/contexts";
import { ShoppingCartSummary as ShoppingCartSummaryUI } from "artisn-ui-react";
import React, { useMemo } from "react";

import useShoppingCartCalculations from "../Checkout/hooks/useShoppingCartCalculation";
import Styles from "./ShoppingCartSummary.styles";
import { ShoppingCartSummaryProps as Props } from "./ShoppingCartSummary.types";
import CONSTANTS from "config/constants";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";

const { ShippingCost, Total } = ShoppingCartSummaryUI;
const { Subtotal, Discount, Points, Taxes } = ShoppingCartSummaryUI;
const { WITH_LOYALTY } = CONSTANTS.FEATURE_FLAGS;

const ShoppingCartSummary: React.FC<Props> = props => {
  const { className } = props;
  const { shoppingCart } = useShoppingCart();
  const { selectedCountry } = useCountries();
  const shoppingCartCalculations = useShoppingCartCalculations();
  const { subTotal, totalDiscount } = shoppingCartCalculations;
  const { shippingCostNet, calculatedTaxes } = shoppingCartCalculations;
  const { totalToPay } = shoppingCartCalculations;
  const defaultFormatter = useDefaultCurrencyFormatter();

  const enhancedShoppingCartTotal = useMemo(() => {
    if (!shoppingCart) return;

    return {
      subtotal: subTotal,
      totalDiscounts: totalDiscount,
      shippingCost: shippingCostNet,
      totalTaxes: calculatedTaxes,
      total: totalToPay,
      totalPoints: 0
    };
  }, [
    calculatedTaxes,
    shippingCostNet,
    shoppingCart,
    subTotal,
    totalDiscount,
    totalToPay
  ]);

  if (!enhancedShoppingCartTotal) return null;

  return (
    <Styles
      className={`ShoppingCartSummary ${className}`}
      shoppingCartTotals={enhancedShoppingCartTotal}
      formatter={defaultFormatter}
    >
      <Subtotal />
      <Discount />
      <ShippingCost />
      <Taxes />
      {WITH_LOYALTY ? (
        <Points
          formatter={{
            currency: selectedCountry.currency,
            locale: selectedCountry.locale,
            symbol: "pts",
            pointsSymbol: "pts"
          }}
        />
      ) : null}
      <Total />
    </Styles>
  );
};

ShoppingCartSummary.defaultProps = {
  className: ""
};

export default ShoppingCartSummary;
