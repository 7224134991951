import { useStores } from "@simple/contexts";
import { useShoppingCart } from "@simple/contexts";
import { useGeo } from "@simple/contexts";
import { useCatalogues } from "@simple/contexts";
import { useProducts } from "@simple/contexts";
import { useFetchUser } from "@simple/services";
import { events } from "artisn/analytics";
import { addProduct, replaceProduct } from "artisn/shopping-cart";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { createShoppingCart, getShoppingCart } from "artisn/shopping-cart";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { flushSync } from "react-dom";

import { addToCartField, defaultConfig } from "./AddToCartButton.helpers";
import { UseAddToCartProps } from "./AddToCartButton.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingCost from "hooks/useShippingCost";
import { getBenefitProductId } from "utils/common.utils";
import { saveProductPreference } from "utils/product.utils";

const { CONTENT_TYPE, SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;
const { logAddProductToCart, logUpdateProductInCart } = events.shoppingCart;

export const useAddToCart = (props: UseAddToCartProps) => {
  const { form, onFinish, config = defaultConfig, onError } = props;
  const { push } = useRouter();
  const { selectedStore } = useStores();
  const { setSelectedProduct } = useProducts();
  const { shoppingCart, temporalBenefit } = useShoppingCart();
  const { selectedCatalogueId } = useCatalogues();
  const shippingCost = useShippingCost();
  const { amount, comment } = config;
  const { selectedCoordinates } = useGeo();
  const [isAdding, setIsAdding] = useState(false);
  const auth = useAuth();
  const { isAnonymous } = auth;
  const { data: user } = useFetchUser(auth);
  const { uid: userUid } = user ?? {};
  const { product, validate } = form ?? {};
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const selectedProductId = product?.productId;

  const selectedProductShoppingCart = useMemo(() => {
    return products?.find(product => product.productId === selectedProductId);
  }, [products, selectedProductId]);

  const selectedProductAmount = selectedProductShoppingCart?.amount;

  const shouldReplace = typeof selectedProductAmount !== "undefined";

  const { lat, lng } = selectedCoordinates ?? {};

  const onClick = useCallback(async () => {
    flushSync(() => {
      setIsAdding(true);
    });

    if (!product || !validate) {
      flushSync(() => {
        setIsAdding(false);
      });
      return;
    }

    const valid = validate() === "OK";
    if (!valid) {
      flushSync(() => {
        setIsAdding(false);
      });
      onError?.();
      return;
    }

    if (shouldReplace) {
      const { id, name, benefits } =
        (await getShoppingCart({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous
        })) ?? {};

      const selectedBenefit = benefits ? benefits[0] : undefined;
      const benefitProductId = getBenefitProductId(selectedBenefit);
      /* If the product being modified is not equal to the benefitId in the
        cart, replace it and continue normally. If not, do nothing. */
      if (benefitProductId !== product.productId) {
        await replaceProduct(product, {
          amount,
          comment,
          store: selectedStore,
          shoppingCartName: name,
          anonymous: isAnonymous
        });
        saveProductPreference(form, userUid);
        setSelectedProduct(undefined);

        if (selectedStore && id && name) {
          logUpdateProductInCart({
            cartId: id,
            cartName: name,
            product,
            store: selectedStore,
            fields: addToCartField,
            contentType: CONTENT_TYPE,
            userType: isAnonymous ? "anonymous" : "registered"
          });
        }
      }
      push("/cart");
      flushSync(() => {
        setIsAdding(false);
      });
      return;
    } else {
      if (!shoppingCart) {
        await createShoppingCart(
          {
            anonymous: isAnonymous
          },
          {
            channelId: +selectedCatalogueId,
            shippingCost,
            latitude: lat ?? 0,
            longitude: lng ?? 0,
            name: SHOPPING_CART_DEFAULT_NAME
          }
        );
      }
      const { id, name } =
        (await getShoppingCart({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous
        })) ?? {};

      /* If a benefit of type PRODUCT exists in context, prevent addProduct
        from firing up. If this condition is removed, a benefit product is added
        first and another product is added to the cart too, removing the
        benefitId key from the product and breaking the coupons functionality.
      */
      if (!temporalBenefit) {
        await addProduct(product, {
          amount,
          store: selectedStore,
          comment,
          shoppingCartName: name,
          anonymous: isAnonymous
        });
        saveProductPreference(form, userUid);
      }

      /* The add product to cart event is kept for coupons because in the
      background, the applyBenefit function runs addProduct too. */
      if (selectedStore && id && name) {
        logAddProductToCart({
          cartId: id,
          cartName: name,
          product,
          store: selectedStore,
          contentType: CONTENT_TYPE,
          userType: isAnonymous ? "anonymous" : "registered"
        });
      }
    }

    await onFinish?.();

    flushSync(() => {
      setIsAdding(false);
    });
  }, [
    product,
    validate,
    shouldReplace,
    onFinish,
    onError,
    isAnonymous,
    push,
    amount,
    form,
    userUid,
    setSelectedProduct,
    selectedStore,
    comment,
    shoppingCart,
    temporalBenefit,
    selectedCatalogueId,
    shippingCost,
    lat,
    lng
  ]);

  return useMemo(() => {
    return { shouldReplace, onClick, isAdding };
  }, [isAdding, onClick, shouldReplace]);
};
