import LogoMobileSVG from "images/logo-mobile.svg";
import LogoSVG from "images/logo.svg";
import Link from "next/link";
import React from "react";

import Styles from "./Logo.styles";
import { LogoProps as Props } from "./Logo.types";
import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const Logo: React.FC<Props> = props => {
  const { className, onClick } = props;
  const label = `${TITLE} logo`;
  const logoNode = <LogoSVG className="Logo__desktop" viewBox="0 0 277 43" />;
  const brandNode = (
    <LogoMobileSVG className="Logo__mobile" viewBox="0 0 154 24" />
  );

  return (
    <Styles className={`Logo ${className}`}>
      {onClick ? (
        <div onClick={onClick} aria-label={label}>
          {brandNode} {logoNode}
        </div>
      ) : (
        <Link href="/" passHref aria-label={label}>
          <a aria-label={label}>
            {brandNode} {logoNode}
          </a>
        </Link>
      )}
    </Styles>
  );
};

Logo.defaultProps = {
  className: ""
};

export default Logo;
