import { useGeo } from "@simple/contexts";
import { useShoppingCart } from "@simple/contexts";
import { applyBenefit, getShoppingCartProducts } from "artisn/shopping-cart";
import { Benefit } from "artisn/types";
import CloseSVG from "images/close.svg";
import React, { useState } from "react";

import BenefitsList from "../BenefitsList/BenefitsList";
import Styles, { ModalHeaderStyled } from "./CouponsModal.styles";
import { CouponsModalProps as Props } from "./CouponsModal.types";
import RedeemCouponInput from "components/Cart/RedeemCoupon/RedeemCouponInput/RedeemCouponInput";
import Button from "components/global/Button/Button";
import Divider from "components/global/Divider/Divider";
import Modal from "components/global/Modal/Modal";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import useShippingCost from "hooks/useShippingCost";
import { createErrorNotification } from "utils/notifications.utils";
import { createSuccessNotification } from "utils/notifications.utils";
import { goToBenefitDetail } from "utils/seo.utils";

const { SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;
const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;

const CouponsModal: React.FC<Props> = props => {
  const { opened, benefits, className, redeemCouponError } = props;
  const { setRedeemCouponError, onClose, redeemHandler } = props;
  const { openProductModal } = props;
  const { isAnonymous } = useAuth();
  const { shoppingCart, setTemporalBenefit } = useShoppingCart();
  const shippingCost = useShippingCost();
  const { selectedCoordinates } = useGeo();
  const t = useI18n();
  const [couponCode, setCouponCode] = useState("");
  const [selectedBenefit, setSelectedBenefit] = useState<Benefit>();
  const [applyBenefitError, setApplyBenefitError] = useState("");
  const { lat, lng } = selectedCoordinates ?? {};
  const empty = benefits && benefits.length === 0;
  const { benefits: cartBenefits } = shoppingCart ?? {};
  const selectedCartBenefit = cartBenefits ? cartBenefits[0] : undefined;
  const disable = empty || !selectedBenefit || !!selectedCartBenefit;

  const resetLocalStates = () => {
    setSelectedBenefit(undefined);
    setApplyBenefitError("");
    setRedeemCouponError("");
    setCouponCode("");
  };

  const closeHandler = () => {
    resetLocalStates();
    onClose?.();
  };

  const applyHandler = async () => {
    if (!selectedBenefit || !shoppingCart) return;

    try {
      setApplyBenefitError("");
      const { benefitId, type } = selectedBenefit;
      if (type === "PRODUCT") {
        setTemporalBenefit(selectedBenefit);

        if (WITH_PRODUCT_MODAL) {
          closeHandler();
          openProductModal();
          return;
        }
        goToBenefitDetail(selectedBenefit);
        return;
      }

      if (
        type === "ALTER_DELIVERY" ||
        type === "DISCOUNT_FIXED" ||
        type === "DISCOUNT_PERCENTAGE"
      ) {
        setTemporalBenefit(undefined);
        if (!getShoppingCartProducts(shoppingCart).length) {
          throw new Error(
            "Can't apply benefit because there are no products in the cart"
          );
        }

        await applyBenefit({
          benefitId,
          latitude: lat,
          longitude: lng,
          shippingCost,
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous,
          shoppingCartId: shoppingCart.id
        });
        createSuccessNotification(
          t.profile.profileCoupons.successApplyCoupon,
          "",
          6000
        );
        closeHandler();
      }
    } catch (e) {
      createErrorNotification(t.profile.profileCoupons.errorRedeemCoupon, "");
      setApplyBenefitError(e.message);
    }
  };

  const modalHeaderNode = (
    <ModalHeaderStyled>
      <p className="CouponsModal__header">{t.profile.profileCoupons.title}</p>
    </ModalHeaderStyled>
  );

  return (
    <Modal
      {...props}
      closeOnClickOutside
      opened={opened}
      header={modalHeaderNode}
      closeIcon={<CloseSVG />}
      backdropConfig={{
        onClick: closeHandler
      }}
      onClose={closeHandler}
    >
      <Styles className={`CouponsModal ${className}`}>
        <div className="CouponsModal__header">
          <div className="CouponsModal__input">
            <RedeemCouponInput
              disabled={!benefits}
              couponCode={couponCode}
              onRedeem={redeemHandler}
              setCouponCode={setCouponCode}
            />
          </div>
          {redeemCouponError ? (
            <p className="CouponsModal__error CouponsModal__redeem-error">
              {redeemCouponError}
            </p>
          ) : null}
          <Divider className="CouponsModal__divider" />
        </div>
        <div className="CouponsModal__body">
          <BenefitsList
            benefits={benefits}
            selectedBenefit={selectedBenefit}
            setSelectedBenefit={setSelectedBenefit}
          />
        </div>
        <div className="CouponsModal__apply">
          {applyBenefitError ? (
            <p className="CouponsModal__error CouponsModal__apply-error">
              {applyBenefitError}
            </p>
          ) : null}
          <Button
            type="FILLED"
            color="primary"
            className="CouponsModal__apply__button"
            disabled={disable}
            onClick={applyHandler}
          >
            {t.coupons.apply}
          </Button>
        </div>
      </Styles>
    </Modal>
  );
};

CouponsModal.defaultProps = {
  className: ""
};

export default CouponsModal;
