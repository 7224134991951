import { ButtonGroup } from "artisn-ui-react";
import EmptySVG from "images/empty-benefit-list.svg";
import React from "react";

import CouponDetail from "../CouponDetail/CouponDetail";
import Styles from "./BenefitsList.styles";
import { BenefitsListProps as Props } from "./BenefitsList.types";
import EmptyState from "components/global/EmptyState/EmptyState";
import useI18n from "hooks/useI18n";

const { Button } = ButtonGroup;

const BenefitsList: React.FC<Props> = props => {
  const { benefits, showRemove = true, inCart = false } = props;
  const { selectedBenefit, setSelectedBenefit } = props;
  const t = useI18n();
  const empty = benefits && benefits.length === 0;

  const emptyNode = (
    <EmptyState
      icon={<EmptySVG />}
      title={t.profile.profileCoupons.empty.title}
      description={t.profile.profileCoupons.empty.description}
    />
  );

  const changeHandler = (benefitId: number) => {
    if (!benefits) return;
    const benefit = benefits.find(item => item.benefitId === benefitId);
    setSelectedBenefit?.(benefit);
  };

  return (
    <Styles className="BenefitsList">
      {empty ? emptyNode : null}
      {benefits?.map((item, index) => {
        const { benefitId } = item;
        const selected = selectedBenefit?.benefitId === benefitId;
        return (
          <Button
            disabled={inCart}
            className="BenefitsList__button"
            value={benefitId}
            type="LINK"
            id={benefitId.toString()}
            key={index}
            onClick={() => changeHandler(benefitId)}
          >
            <CouponDetail
              key={index}
              inCart={inCart}
              showRemove={showRemove}
              benefit={item}
              selected={selected}
            />
          </Button>
        );
      })}
    </Styles>
  );
};

BenefitsList.defaultProps = {};

export default BenefitsList;
