import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import BellSVG from "images/TabsMenu/bell.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./NotificationsButton.styles";
import { NotificationsButtonProps as Props } from "./NotificationsButton.types";
import useI18n from "hooks/useI18n";

const { Item } = TabsMenuUI;

const NotificationsButton: React.FC<Props> = props => {
  const { className, hideTitle } = props;
  const { push } = useRouter();
  const t = useI18n();

  const onClickHandler = async () => {
    await push("/profile/notifications");
  };

  return (
    <Styles className={`NotificationsButton ${className}`}>
      <Item
        icon={BellSVG}
        title={hideTitle ? undefined : t.navigation.notification}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

NotificationsButton.defaultProps = {};

export default NotificationsButton;
