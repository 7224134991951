import styled from "styled-components";

import { SignInButtonStyledProps as Props } from "./SignInButton.types";
import CONSTANTS from "config/constants";
const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const SignInButtonStyled = styled.button<Props>`
  width: 12.8rem;
  height: 5.2rem;
  background-color: var(--palette-yellow-s100-l55);
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--palette-primary);
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  margin-right: 1.6rem;
  border: none;

  @media (width <= ${tablet}px) {
    width: 9.2rem;
    height: 4rem;
    margin-right: 0rem;
  }

  .SignInButton {
  }
`;

export default SignInButtonStyled;
