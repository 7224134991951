import HeartSVG from "images/heart-line.svg";
import FilledHeartSVG from "images/heart-primary-filled.svg";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

import Styles from "./AddToWishlistButton.styles";
import { AddToWishlistButtonProps as Props } from "./AddToWishlistButton.types";
import { useAddToWishlist } from "components/products/AddToWishlistButton/AddToWishListButton.hooks";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { dismissAddToWishlistNotification } from "utils/notifications.utils";
import { createAddToWishlistNotification } from "utils/notifications.utils";

const { SHOPPING_CART_WISHLIST_NAME } = CONSTANTS.ARTISN;
const { WITH_ANONYMOUS } = CONSTANTS.FEATURE_FLAGS;

const AddToWishlistButton: React.FC<Props> = props => {
  const { className, disabled, product } = props;
  const [showNotification, setShowNotification] = useState(false);
  const enhancedConfig = {
    shoppingCartName: SHOPPING_CART_WISHLIST_NAME
  };
  const { onClick, inCart } = useAddToWishlist({
    product,
    config: enhancedConfig
  });
  const text = inCart ? "Remover de favoritos" : "Añadir a favoritos";
  const { isAnonymous } = useAuth();
  const { push } = useRouter();

  const handlerClick = () => {
    if (!WITH_ANONYMOUS && isAnonymous) {
      push("/signin");
      return;
    }
    onClick();
    if (!inCart) setShowNotification(true);
  };

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      onClick();
      dismissAddToWishlistNotification();
    },
    [onClick]
  );

  useEffect(() => {
    if (!inCart || !showNotification) return;
    dismissAddToWishlistNotification();
    createAddToWishlistNotification(product, onDelete);
    setShowNotification(false);
  }, [inCart, product, onDelete, showNotification]);

  return (
    <Styles
      className={`AddToWishlistButton ${className}`}
      onClick={handlerClick}
      disabled={disabled || !product?.available}
      color="primary-light"
    >
      {inCart ? <FilledHeartSVG /> : <HeartSVG />}
      <p className="AddToWishlistButton__text">{text}</p>
    </Styles>
  );
};

AddToWishlistButton.defaultProps = {
  className: ""
};

export default AddToWishlistButton;
