import { useCountries } from "@simple/contexts";
import { useProductCard } from "artisn-ui-react";
import { getProductTotals } from "artisn/products";
import React from "react";

import IvaInfo from "../IvaInfo/IvaInfo";
import ProductBasePriceInfo from "../ProductBasePriceInfo/ProductBasePriceInfo";
import Styles from "./ProductBaseInfo.styles";
import { ProductBaseInfoProps as Props } from "./ProductBaseInfo.types";
import useI18n from "hooks/useI18n";

const ProductBaseInfo: React.FC<Props> = props => {
  const { product, className } = props;
  const { decimals } = useCountries().selectedCountry;
  const t = useI18n();

  const { totals } = useProductCard(product, { decimals });
  const { name, measure } = product;
  const { additionalInfo } = product ?? {};
  const { basePrice } = additionalInfo ?? {};
  const { totalTaxes } = getProductTotals(product, 1);

  return (
    <Styles className={`ProductBaseInfo ${className}`}>
      <p className="ProductBaseInfo__brand">{t.common.brand}: Pure Nature</p>
      <h1 className="ProductBaseInfo__name">{name}</h1>
      <p className="ProductBaseInfo__presentation">
        {t.common.presentation}: {measure ?? ""}
      </p>
      <ProductBasePriceInfo productTotals={totals} basePrice={basePrice} />
      {totalTaxes !== 0 ? <IvaInfo /> : null}
    </Styles>
  );
};

ProductBaseInfo.defaultProps = {
  className: ""
};

export default ProductBaseInfo;
