import { Clickable } from "artisn-ui-react";
import ChevronRightSVG from "images/chevron-right-black.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./CategoriesButton.styles";
import { CategoriesButtonProps as Props } from "./CategoriesButton.types";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import useWindowSize from "hooks/useWindowSize";

const { BREAKPOINTS } = CONSTANTS;
const { desktop } = BREAKPOINTS;

const CategoriesButton: React.FC<Props> = props => {
  const { className } = props;
  const { push } = useRouter();
  const { width } = useWindowSize();
  const isMobile = width < desktop;
  const t = useI18n();
  const { categories, viewCategories } = t.navigation;

  const clickHandler = () => {
    push("/categories");
  };

  const title = isMobile ? categories : viewCategories;

  return (
    <Styles className={`CategoriesButton ${className}`}>
      <Clickable onClick={clickHandler} className="CategoriesButton__container">
        <p className="CategoriesButton__title">{title}</p>
        <ChevronRightSVG className="CategoriesButton__chevron" />
      </Clickable>
    </Styles>
  );
};

CategoriesButton.defaultProps = {
  className: ""
};

export default CategoriesButton;
