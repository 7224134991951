import React from "react";

import Styles from "./SignInButton.styles";
import { SignInButtonProps as Props } from "./SignInButton.types";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";

const SignInButton: React.FC<Props> = props => {
  const t = useI18n();
  const { toggleSignInModal } = useAuth();

  return (
    <Styles className="SignInButton" onClick={toggleSignInModal}>
      {t.common.signIn}
    </Styles>
  );
};
export default SignInButton;
