import styled from "styled-components";

import { ProductMainStyledProps as Props } from "./ProductMain.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile, desktop } = BREAKPOINTS;
const { primary } = variables.fonts;

const ProductMainStyled = styled.div<Props>`
  font-family: ${primary};

  .ProductMain {
    &__content {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 6rem;
      padding-top: 2.4rem;
      justify-content: center;

      @media (max-width: ${tablet}px) {
        justify-content: center;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 4rem;
        width: 100%;
      }
    }

    &__wishlist-heart {
      padding: 0;
    }

    &__icons {
      display: flex;
    }

    &__preview-image {
      flex: 1 0 0;
      display: flex;
      justify-content: center;
      position: relative;
      align-items: flex-start;

      @media (max-width: ${tablet}px) {
        width: auto;
        min-width: none;
      }

      & .ProductDetailsImage {
        position: sticky;
        top: 8rem;
      }
    }

    &__form {
      flex: 1 0.3 0;
      min-width: 38rem;
      padding-left: 3.2rem;

      @media (max-width: 851px) {
        padding-top: 3.2rem;
        padding-left: 0;
      }

      @media (max-width: ${mobile}px) {
        width: 100%;
        min-width: auto;
      }
    }

    &__baseInfo-icons {
      display: ${props => (props.isModal ? "none" : "flex")};

      .ProductModal {
        &__share {
          margin-left: 1.6rem;
          .Clickable {
            padding: 0;
          }
        }
      }

      .ProductBasePriceInfo {
        &__dollars {
          color: var(
            ${props =>
              props.purchaseWithPoints
                ? "--palette-black-s0-l10"
                : "--palette-primary"}
          );
          font-size: 2.4rem;
        }
        &__points {
          font-size: 2.4rem;
          color: var(
            ${props =>
              props.purchaseWithPoints
                ? "--palette-primary"
                : "--palette-black-s0-l10"}
          );
        }
      }

      @media (max-width: ${tablet}px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__description {
      padding-bottom: 3.2rem;

      &__title {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2rem;
        color: var(--palette-gray-s0-l35);
      }

      &__text {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2rem;
        color: var(--palette-gray-s0-l35);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: ${props =>
          props.showCompleteDescription ? "unset" : "5"};
      }

      &__btn {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 600;
        color: var(--palette-black-s0-l10);
        padding: 0;
        margin: 0;
        padding-top: 0.8rem;
      }
    }

    &__points {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 2.4rem;

      @media (min-width: ${desktop}px) {
        padding-bottom: 3.2rem;
      }

      &--title {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.8rem;
        color: var(--palette-black-s0-l10);
      }

      &--points {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var(--palette-gray-s0-l35);
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      padding-top: 3.2rem;

      @media (max-width: ${tablet}px) {
        position: fixed;
        display: flex;
        justify-content: space-around;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--palette-white);
        padding: 1.2rem 0.4rem;
        z-index: 3;
        align-items: center;
        box-shadow: 0 -0.1rem 0.8rem 0.1rem var(--palette-gray-s0-l20-a20);
      }
    }

    &__counter {
      margin-right: 0.8rem;
      background-color: var(--palette-white);
      border: 0.1rem solid var(--palette-purple-s48-l39);
      border-radius: 10rem;
      overflow: hidden;
      display: flex;

      & .Counter {
        &__button {
          width: 4.2rem;
          height: 3.2rem;
          background-color: var(--palette-white);
          svg {
            path {
              fill: var(--palette-purple-s48-l39);
            }
          }
        }

        &__quantity {
          background-color: var(--palette-white);
          color: var(--palette-black-s0-l10);
        }

        @media (max-width: ${tablet}px) {
          margin-left: 0;
        }
      }

      @media (max-width: ${tablet}px) {
        display: flex;
        align-self: center;
        order: -1;
      }

      @media (max-width: ${mobile}px) {
        margin-right: 0;
      }
    }

    &__addToCart .Button {
      @media (max-width: ${tablet}px) {
        flex-wrap: wrap;
        display: flex;
        align-self: center;
        padding: 0.8rem 2.4rem;
      }
    }

    &__limit {
      padding-top: 2.4rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.8rem;
      color: var(--palette-gray-s0-l35);

      @media (max-width: ${mobile}px) {
        display: none;
      }
    }

    &__limit-support {
      text-decoration: none;
      font-weight: bold;
      color: var(--palette-black-s0-l10);
    }
  }
`;

export default ProductMainStyled;
