import styled from "styled-components";

import { CouponDetailStyledProps as Props } from "./CouponDetail.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { mobile } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const CouponDetailStyled = styled.div<Props>`
  font-family: ${primary};
  display: flex;
  margin-bottom: 0.8rem;
  flex-direction: column;
  flex: 1;
  font-family: ${primary};

  .CouponDetail {
    &__container {
      display: flex;
      justify-content: space-between;
      min-height: 7.5rem;
      background-color: ${props =>
        props.selected
          ? "var(--palette-orange-s92-l95)"
          : "var(--palette-white)"};
      border-radius: 1.6rem;
      padding: 0 0.8rem;
      border: 0.1rem solid var(--palette-gray-s4-l91);

      @media (max-width: ${mobile}px) {
        padding: 0.8rem;
      }
    }

    &__left {
      display: flex;
      text-align: left;
      align-items: center;
      gap: 0.5rem;

      &__icon {
        min-width: 2.4rem;
        min-height: 2.4rem;
        border: 0.1rem solid var(--palette-gray-s0-l40);
        border-radius: 10rem;
        margin-right: 1.2rem;
        align-self: center;
        background-color: var(--palette-white);
      }

      &__selected {
        background-color: var(--palette-white);
        min-width: 2.4rem;
        min-height: 2.4rem;
        border: 0.6rem solid var(--palette-primary);
        border-radius: 10rem;
        margin-right: 1.2rem;
      }

      &__label {
        &__title {
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 2rem;
          color: var(--palette-black-s0-l10);
          padding-bottom: 0.4rem;
        }

        &__description {
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem;
          color: var(--palette-black-s0-l10);
          padding-bottom: 0.4rem;

          span {
            font-size: 1.2rem;
            font-weight: 400;
          }
        }

        &__expiration-date {
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: var(--palette-gray-s0-l35);
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-self: center;
    }

    &__use-points {
      color: var(--palette-orange-s89-l54);

      span {
        font-size: 1.6rem;
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }

    &__remove-error {
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
      padding-top: 0.4rem;
    }
  }
`;

export default CouponDetailStyled;
