import React from "react";

import GoBack from "../GoBack/GoBack";
import Logo from "../Logo/Logo";
import SearchBarProducts from "../SearchBarProducts/SearchBarProducts";
import SelectAddressButton from "../SelectAddressButton/SelectAddressButton";
import CartButton from "../navigation/CartButton/CartButton";
import CategoriesButton from "../navigation/CategoriesButton/CategoriesButton";
import NotificationsButton from "../navigation/NotificationsButton/NotificationsButton";
import UserTabsMenuItem from "../navigation/UserTabsMenuItem/UserTabsMenuItem";
import Welcome from "../navigation/Welcome/Welcome";
import Styles from "./Navbar.styles";
import { NavbarProps as Props } from "./Navbar.types";
import SignInButton from "./SignInButton/SignInButton";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";

const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_SELECT_ADDRESS_DROPDOWN, WITH_TALK_SHOP } = FEATURE_FLAGS;
const { WITH_PURCHASE } = FEATURE_FLAGS;

const Navbar: React.FC<Props> = props => {
  const { className, showBrand = false, title, icon, product, to } = props;
  const { showBottomSearch = true, showCart = true, shareIcon } = props;
  const notHome = !showBrand ? "not-home" : "";
  const isShowCart = !showCart ? "Navbar__btn--hidden" : null;
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = WITH_TALK_SHOP && talkShopProvider && talkShopIdentifier;
  const { isAnonymous } = useAuth();

  const renderCartButton = () => {
    if (!WITH_PURCHASE) return null;
    return (
      <CartButton
        className={`Navbar__btn Navbar__btn--cart ${isShowCart}`}
        product={product}
      />
    );
  };

  const iconNode = (
    <div className="Navbar__icon">
      {renderCartButton()}
      {shareIcon}
    </div>
  );

  return (
    <Styles
      className={`Navbar ${className}`}
      showBottomSearch={showBottomSearch}
      talkShop={!!talkShop}
      id="navbar"
      isAnonymous={isAnonymous}
    >
      <main className="Navbar__content">
        <div className={`Navbar__top Navbar__top--responsive ${notHome}`}>
          <GoBack to={to} className="Navbar__back" />
          {title || title === "" ? (
            <p className="Navbar__title">{title}</p>
          ) : (
            <SearchBarProducts className="Navbar__search-bar Navbar__search-bar--center" />
          )}
          {icon ?? iconNode}
        </div>
        <div className={`Navbar__top Navbar__top--brand ${notHome}`}>
          {talkShop ? (
            <Logo className="Navbar__logo" onClick={() => {}} />
          ) : (
            <Logo className="Navbar__logo" />
          )}
          <CategoriesButton className="Navbar__categories" />
          <SearchBarProducts className="Navbar__search-bar Navbar__search-bar--top" />
          {WITH_SELECT_ADDRESS_DROPDOWN ? (
            <SelectAddressButton className="Navbar__dropdown-desktop" />
          ) : null}
          <div className="Navbar__tabs">
            {isAnonymous ? (
              <SignInButton />
            ) : (
              <Welcome className="Navbar__welcome" />
            )}

            <UserTabsMenuItem
              hideTitle
              hideMobileMode
              className="Navbar__btn Navbar__btn--profile"
              active={false}
            />
            <CartButton
              className="Navbar__btn Navbar__btn--cart"
              product={product}
            />
            <NotificationsButton
              hideTitle
              className="Navbar__btn Navbar__btn--coupons"
            />
          </div>
        </div>
        {showBottomSearch ? (
          <div className="Navbar__bottom">
            <SearchBarProducts className="Navbar__search-bar Navbar__search-bar--bottom" />
          </div>
        ) : null}
      </main>
      {WITH_SELECT_ADDRESS_DROPDOWN ? (
        <SelectAddressButton className="Navbar__dropdown-mobile" />
      ) : null}
    </Styles>
  );
};

Navbar.defaultProps = {
  className: ""
};

export default Navbar;
