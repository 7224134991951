import CartEmptySVG from "images/cart-empty.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./EmptyCart.styles";
import { EmptyCartProps as Props } from "./EmptyCart.types";
import Button from "components/global/Button/Button";

const EmptyCart: React.FC<Props> = props => {
  const router = useRouter();
  const goToCategories = () => router.push("/categories");

  return (
    <Styles className="EmptyCart">
      <div className="EmptyCart__icon">
        <CartEmptySVG />
      </div>
      <p className="EmptyCart__title">Añade productos a tu carrito</p>
      <div className="EmptyCart__description">
        Explora todos nuestros productos y comienza a añadir tus productos
        favoritos
      </div>
      <Button className="EmptyCart__button" onClick={goToCategories}>
        Explorar productos
      </Button>
    </Styles>
  );
};

EmptyCart.defaultProps = {};

export default EmptyCart;
