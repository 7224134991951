import InformationSVG from "images/information.svg";
import React from "react";

import Styles from "./IvaInfo.styles";
import { IvaInfoProps as Props } from "./IvaInfo.types";
import useI18n from "hooks/useI18n";

const IvaInfo: React.FC<Props> = props => {
  const { className, isSummary } = props;
  const t = useI18n();

  return (
    <Styles isSummary={isSummary} className={`IvaInfo ${className}`}>
      <InformationSVG />
      <p className="IvaInfo__title">{t.common.iva}</p>
    </Styles>
  );
};

IvaInfo.defaultProps = {
  className: ""
};

export default IvaInfo;
