import { useShoppingCart } from "@simple/contexts";
import { Badge } from "artisn-ui-react";
import { redeemCoupon } from "artisn/shopping-cart";
import ChevronRightSVG from "images/chevron-right-black.svg";
import Coupon from "images/coupon.svg";
import UserSVG from "images/iconProfile.svg";
import React, { useState, useMemo } from "react";

import Styles from "./RedeemCoupon.styles";
import { RedeemCouponProps as Props } from "./RedeemCoupon.types";
import RedeemCouponInput from "./RedeemCouponInput/RedeemCouponInput";
import CouponsModal from "components/coupons/CouponsModal/CouponsModal";
import Button from "components/global/Button/Button";
import ProductModal from "components/global/ProductModal/ProductModal";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import { getBenefitProductId } from "utils/common.utils";
import { createSuccessNotification } from "utils/notifications.utils";

const { SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;

const RedeemCoupon: React.FC<Props> = props => {
  const { className } = props;
  const { benefitsWallet, temporalBenefit } = useShoppingCart();
  const t = useI18n();
  const [opened, setOpened] = useState(false);
  const { isAnonymous, toggleSignInModal } = useAuth();
  const [redeemCouponError, setRedeemCouponError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { benefits } = benefitsWallet ?? {};
  const benefitsLength = useMemo(() => benefits?.length, [benefits]);
  const productId = getBenefitProductId(temporalBenefit);

  const redeemHandler = async (code: string) => {
    if (code.trim() === "") return;

    try {
      setRedeemCouponError("");
      await redeemCoupon(
        { shoppingCartName: SHOPPING_CART_DEFAULT_NAME },
        code
      );
      setCouponCode("");
      createSuccessNotification(t.profile.profileCoupons.successRedeemCoupon);
    } catch {
      setRedeemCouponError(t.profile.profileCoupons.errorRedeemCoupon);
    }
  };

  const openHandler = () => {
    setRedeemCouponError("");
    setOpened(prev => !prev);
  };

  const onClickModal = () => {
    setIsOpenModal(true);
  };

  const buttonNode = (
    <Button
      color="primary"
      className="RedeemCoupon__button-wallet"
      onClick={() => openHandler()}
    >
      <Coupon />
    </Button>
  );

  const renderCouponRedeem = (
    <div className="RedeemCoupon__container">
      <RedeemCouponInput
        className="RedeemCoupon__input"
        disabled={!benefits}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
        onRedeem={redeemHandler}
      />
      {benefitsLength ? (
        <Badge
          value={benefitsLength.toString()}
          className="RedeemCoupon__badge"
        >
          {buttonNode}
        </Badge>
      ) : (
        <div className="RedeemCoupon__badge">{buttonNode}</div>
      )}
    </div>
  );

  const renderAnonymousSignIn = (
    <Button
      className="RedeemCoupon__button"
      type="BORDER"
      onClick={toggleSignInModal}
    >
      <UserSVG />
      <h2>{t.coupons.signIn}</h2>
      <ChevronRightSVG />
    </Button>
  );

  return (
    <Styles className={`RedeemCoupon ${className}`}>
      {!isAnonymous ? renderCouponRedeem : renderAnonymousSignIn}
      {redeemCouponError ? (
        <p className="RedeemCoupon__redeem-error">{redeemCouponError}</p>
      ) : null}
      <CouponsModal
        opened={opened}
        benefits={benefits}
        redeemCouponError={redeemCouponError}
        redeemHandler={redeemHandler}
        openProductModal={onClickModal}
        setRedeemCouponError={setRedeemCouponError}
        onClose={() => setOpened(prev => !prev)}
      />
      <ProductModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        productId={productId}
      />
    </Styles>
  );
};

RedeemCoupon.defaultProps = {
  className: ""
};

export default RedeemCoupon;
