import styled from "styled-components";

import { RedeemCouponStyledProps as Props } from "./RedeemCoupon.types";

const RedeemCouponStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;

  .Badge {
    &__value {
      min-width: 2.4rem;
      height: 2rem;
      font-size: 1.2rem;
      z-index: 1;
      padding: 0 0.4rem;
    }
  }

  .RedeemCoupon {
    &__button {
      border-radius: 1.2rem;
      border: 0.1rem solid var(--palette-primary);
      background-color: var(--palette-orange-h19-s89-l54-a008);
      color: var(--palette-black);
      font-weight: 500;
      font-size: 1.4rem;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4.4rem;

      &:hover {
        cursor: pointer;
        background-color: var(--palette-orange-h19-s89-l54-a008) !important;
        color: var(--palette-black) !important;
      }
    }

    &__container {
      display: flex;
      align-items: flex-end;
    }

    &__redeem-error {
      padding-top: 0.8rem;
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }

    &__button-wallet {
      width: 5.6rem;
      height: 5.6rem;
      padding: 0;
      border: none;
      background-color: var(--palette-green-s90-l35);
    }

    &__input {
      width: calc(100% - 8rem);
      z-index: 1;
    }

    &__badge {
      margin-left: 0.8rem;
      z-index: 1;

      .Badge__value {
        background-color: var(--palette-yellow-s100-l50);
        color: var(--palette-black-s0-l10);
      }
    }
  }
`;

export default RedeemCouponStyled;
